/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// ------------------------------------
// VSL Scripts Typography
// ------------------------------------

@import "@styles/base/bootstrap-extended/include"; // Bootstrap includes
@import "@styles/base/components/include"; // Components includes

// ** -----------------------
// VSL - Design System
// ** -----------------------
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  line-height: 1.175;
}

h5 {
  line-height: 1.6;
}

h6 {
  line-height: 1.64;
}

label,
a {
  font-size: 12px;
  line-height: 1.5;
  margin: 8px 0px;
}

.paragraph-lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.175;
}

.paragraph-lead-semi-bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.175;
}

.paragraph {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

.paragraph-semi-bold {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.paragraph-bold {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}

.paragraph-small {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.paragraph-small-semi-bold {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
}

.paragraph-small-bold {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
}

.deleted {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 400;
  text-decoration: line-through;
}

.italicized {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 400;
  font-style: italic;
}

.underlined {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 400;
  text-decoration: underline;
}

.text-offwhite {
  color: $text-offwhite !important;
}

.text-darker {
  color: $text-darker !important;
}

.text-lighter {
  color: $text-lighter !important;
}

.text-purple-400 {
  color: $purple-400 !important;
}

.text-neutral-white {
  color: $text-white;
}

.text-error {
  color: $red;
}

.mb-8px {
  margin-bottom: 8px;
}

.mb-4px {
  margin-bottom: 4px;
}

// End - VSL Design System

.menu-item {
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 16px;
}

li.nav-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

li.nav-item.active {
  z-index: 1;
  .menu-item {
    color: $primary !important;
  }
  svg {
    color: $primary !important;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-left: 4px solid $primary;
    top: 25%;
    height: 50%;
  }
}

.main-menu.menu-dark .navigation > li.active > a {
  background: transparent !important;
  box-shadow: unset !important;
}

.main-menu.menu-dark .navigation > li ul .active {
  background: transparent !important;
  box-shadow: unset !important;
}

.collapse.show {
  li.nav-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 20px !important;
    padding-left: 10px;
  }
}

.navbar-header {
  height: 115px !important;
  display: grid;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid darken($text-darker, 15%);
    width: 80%;
    left: 10%;
  }
}

.main-menu .main-menu-content {
  height: calc(100% - #{$navbar-height} - 110px) !important;
}

.vsl-card-dashed {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;

  background: $bg-dark-500;

  border: 1px dashed $bg-dark-100;
  width: fit-content;
  border-radius: 5px;
}

.mb-100 {
  margin-bottom: 100px;
}

.box-container {
  height: 636px;
  padding: 24px;
  /* Background/Dark/400 */
  background: #16161e;
  border-radius: 5px;

  box-sizing: border-box;
}

.input-group {
  margin-top: 10px;
  Label {
    margin-right: 15px;
  }
  .radio-group {
    Input {
      margin-bottom: 15px;
      margin-right: 10px;
    }
  }
}

nav.header-navbar.navbar {
  max-width: 1224px !important;
}

.content-wrapper {
  max-width: 1224px !important;
}